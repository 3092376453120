import i18n from '@/i18n';
import moment from 'moment';
import { cleanConfigFormData } from '@/utils/format';

const PACKAGING_PHOTO = [
    {
        id: 0,
        label: 'なし'
    },
    {
        id: 1,
        label: 'あり'
    }
];

function initFormPrize() {
    return {
        digital_gifts: {},
        configFieldSelectDigitalGifts: {
            trackBy: 'id',
            label: 'value',
            errorField: 'デジタルギフト ★ ',
            valueSync: null,
            error: false,
            isRemove: true,
            isRequired: false,
            typesValidate: []
        },
        type: null,
        title: '',
        configFieldTitle: {
            error: false
        },
        configFieldSelectType: {
            trackBy: 'id',
            label: 'value',
            errorField: '種類',
            valueSync: null,
            error: false,
            isRemove: true,
            isRequired: false,
            typesValidate: []
        },
        campaign: null,
        configFieldCampaign: { error: false },
        name: '',
        configFieldName: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: '賞品名',
            errorCustom: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        quantity: '',
        coupon_value: '',
        configFieldCouponValue: {
            maxlength: 10,
            isNumber: true,
            error: false
        },
        scheduled_arrived_at: '',
        scheduled_arrived_at_y: '',
        scheduled_arrived_at_m: '',
        scheduled_arrived_at_d: '',
        configFieldScheduledArrivedAt: {
            error: false,
            errorText: '',
            errorField: '入庫予定日',
            typesValidate: ['date']
        },
        is_packaging_photo_required: 0,
        warehousing_date: '',
        warehousing_date_y: '',
        warehousing_date_m: '',
        warehousing_date_d: '',
        configFieldWarehousingDate: {
            error: false,
            errorText: '',
            errorField: '入庫日',
            typesValidate: ['date']
        },
        shipping_date: '',
        shipping_date_d: '',
        shipping_date_m: '',
        shipping_date_y: '',
        configFieldShippingDate: {
            error: false,
            errorText: '',
            errorField: '発送日',
            typesValidate: ['date']
        },
        warehousing_quantity: '',
        shipment_quantity: '',
        remaining_quantity: 0,
        notification: '',
        configFieldRNotification: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: '連絡事項',
            errorCustom: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        reconfirmation_schedule: '',
        configFieldReconfirmationSchedule: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: '再確認日程',
            errorCustom: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        instructions_regarding_prizes: '',
        configFieldInstructionsRegardingPrizes: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: '賞品あまり対応指示項目',
            errorCustom: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        month_start_fee_apply: '',
        configFieldMothStartFeeApply: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: '保管料がかかり始める月',
            errorCustom: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        storage_material: '',
        configFieldStorageMaterial: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: '保管料',
            errorCustom: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        boxes_number: '',
        configFieldBoxesNumber: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: '箱数',
            errorCustom: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        number_of_applications: {
            total: '',
            postcard_mail: '',
            web: '',
            palnavi: '',
            sns: '',
            twitter: '',
            instagram: '',
            line: '',
            others: '',
            invalid: ''
        },
        number_of_inquiries: {
            total: '',
            tel: '',
            email: '',
            number_of_cases: '',
            irregular: '',
            irregular_content: ''
        },
        number_of_win_prize: 0,
        number_of_people_drawing_lots: 0,
        quantity_for_event_prize: 0,
        estimated_remaining_quantity: 0,
        detail_information: {
            delivery_company: '',
            configDeliveryCompany: {
                isRequired: false,
                isMaxLength: false,
                error: false,
                errorField: i18n.t('pageCampaign.tabPrize.shippingCompany')
            },
            tracking_number: '',
            configTrakingNumber: {
                isRequired: false,
                isMaxLength: false,
                error: false,
                errorField: i18n.t('pageCampaign.tabPrize.trackingNumber')
            },
            number_of_boxes_to_be_stocked: '',
            configNumberOfBoxesToBeStocked: {
                isRequired: false,
                isMaxLength: false,
                error: false,
                errorField: i18n.t('pageCampaign.tabPrize.expectedNumberOfBoxesInStock')
            },
            expiration_date: '',
            configExpirationDate: {
                isRequired: false,
                isMaxLength: false,
                error: false,
                errorField: i18n.t('pageCampaign.tabPrize.expirationDate')
            },
            storage_warehouse: '',
            configStorageWarehouse: {
                error: false,
                isRemove: false,
                loading: false,
                trackBy: 'id',
                label: 'value',
                openDirection: 'top'
            },
            locator_number: '',
            configLocatorNumber: {
                isRequired: false,
                isMaxLength: false,
                error: false,
                errorField: i18n.t('pageCampaign.tabPrize.locatorNumber')
            }
        },
        delivery_date_schedule: null,
        delivery_date: null,
        delivery_quantity: 0,
        remaining_response: {
            prize_less_status: null,
            configReturnPrizeStatus: {
                error: false,
                isRemove: false,
                loading: false,
                trackBy: 'id',
                label: 'value'
            },
            reconfirmation_date: null,
            reconfirmation_date_y: null,
            reconfirmation_date_m: null,
            reconfirmation_date_d: null,
            configReconfirmationDate: {
                error: false,
                errorText: ''
            },
            prize_refund_instructions: '',
            configPrizeRefundInstructions: {
                isRequired: false,
                isMaxLength: false,
                error: false,
                errorField: i18n.t('pageCampaign.tabPrize.prizeResponseInstructions')
            },
            preserve_fee: '',
            configPreserveFee: {
                isRequired: false,
                isMaxLength: false,
                error: false,
                errorField: i18n.t('pageCampaign.tabPrize.storageFee')
            },
            date_of_preserve: null,
            date_of_preserve_y: null,
            date_of_preserve_m: null,
            date_of_preserve_d: null,
            configDateOfPreserve: {
                error: false,
                errorText: ''
            },
            reference: '',
            configReference: {
                isRequired: false,
                isMaxLength: false,
                error: false,
                errorField: i18n.t('pageCampaign.tabPrize.remarks')
            }
        },
        memo: '',
        configFieldMemo: {
            errorCustomMess: '',
            valueSync: '',
            isRequired: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pageCampaign.tabPrize.memo'),
            typesValidate: ['max255']
        },
        configFieldQuantity: {
            errorCustomMess: '',
            valueSync: '',
            isNumber: true,
            isRequired: false,
            isMaxLength: false,
            error: false,
            errorField: '数量',
            typesValidate: ['max10']
        },
        configFieldQuantityForEventPrize: {
            errorCustomMess: '',
            valueSync: '',
            isNumber: true,
            isRequired: false,
            isMaxLength: false,
            error: false,
            errorField: '賞品必要数',
            typesValidate: ['max10']
        },
        configFieldWarehousingQuantity: {
            errorCustomMess: '',
            valueSync: '',
            isNumber: true,
            isRequired: false,
            isMaxLength: false,
            error: false,
            errorField: '入庫数',
            typesValidate: ['max10']
        },
        configFieldShipmentQuantity: {
            errorCustomMess: '',
            valueSync: '',
            isNumber: true,
            isRequired: false,
            isMaxLength: false,
            error: false,
            errorField: '発送数',
            typesValidate: ['max10']
        }
    };
}

function handleDataDetail(data, form, digitalObject, noteGift) {
    form = {
        ...form,
        ...data,
        number_of_applications: data.number_of_applications
            ? data.number_of_applications
            : {
                  total: 0,
                  postcard_mail: '',
                  web: '',
                  palnavi: '',
                  sns: '',
                  twitter: '',
                  instagram: '',
                  line: '',
                  others: '',
                  invalid: ''
              },
        number_of_inquiries: data.number_of_inquiries
            ? data.number_of_inquiries
            : {
                  total: 0,
                  tel: '',
                  email: '',
                  number_of_cases: '',
                  irregular: '',
                  irregular_content: ''
              },
        title: data.event_prizes.title ?? '',
        campaign: data.event_prizes.campaign.name ?? null,
        detail_information: {
            ...form.detail_information,
            delivery_company: data?.detail_information?.delivery_company ?? '',
            tracking_number: data?.detail_information?.tracking_number ?? '',
            number_of_boxes_to_be_stocked: data?.detail_information?.number_of_boxes_to_be_stocked ?? '',
            expiration_date: data?.detail_information?.expiration_date ?? '',
            storage_warehouse: data?.detail_information?.storage_warehouse ?? null,
            locator_number: data?.detail_information?.locator_number ?? ''
        },
        delivery_date_schedule: data?.event_prizes.delivery_date_schedule ?? null,
        delivery_date: data?.event_prizes.delivery_date ?? null,
        delivery_quantity: data?.event_prizes?.delivery_quantity ?? 0,
        remaining_response: {
            ...form.remaining_response,
            prize_less_status: data?.remaining_response?.prize_less_status?.id ? data?.remaining_response?.prize_less_status : null,
            reconfirmation_date: data?.remaining_response?.reconfirmation_date ?? null,
            reconfirmation_date_y: data?.remaining_response?.reconfirmation_date
                ? moment(data?.remaining_response?.reconfirmation_date).year().toString()
                : null,
            reconfirmation_date_m: data?.remaining_response?.reconfirmation_date
                ? (moment(data?.remaining_response?.reconfirmation_date).month() + 1).toString()
                : null,
            reconfirmation_date_d: data?.remaining_response?.reconfirmation_date
                ? moment(data?.remaining_response?.reconfirmation_date).date().toString()
                : null,
            prize_refund_instructions: data?.remaining_response?.prize_refund_instructions ?? '',
            preserve_fee: data?.remaining_response?.preserve_fee ?? '',
            date_of_preserve: data?.remaining_response?.date_of_preserve ?? null,
            date_of_preserve_y: data?.remaining_response?.date_of_preserve
                ? moment(data?.remaining_response?.date_of_preserve).year().toString()
                : null,
            date_of_preserve_m: data?.remaining_response?.date_of_preserve
                ? (moment(data?.remaining_response?.date_of_preserve).month() + 1).toString()
                : null,
            date_of_preserve_d: data?.remaining_response?.date_of_preserve
                ? moment(data?.remaining_response?.date_of_preserve).date().toString()
                : null,
            reference: data?.remaining_response?.reference ?? ''
        },
        number_of_win_prize: data?.event_prizes?.number_of_win_prize ?? 0,
        number_of_people_drawing_lots: data?.event_prizes?.number_of_people_drawing_lots ?? 0
    };
    if (form.digital_gifts[0]) digitalObject = form.digital_gifts[0];
    if (form.digital_gifts[0] && form.digital_gifts[0].extension) noteGift = form.digital_gifts[0].extension.value_note;
    return {
        form,
        digitalObject,
        noteGift
    };
}

function handleMapDataForm(form, digitalObject, noteGift) {
    let obj = { ...form };
    obj.type = {
        id: obj.type?.id
    };

    if (obj.expiration_date_y && obj.expiration_date_m && obj.expiration_date_d)
        obj.expiration_date = obj.expiration_date_y + '-' + obj.expiration_date_m + '-' + obj.expiration_date_d;
    obj.warehousing_quantity = obj.warehousing_quantity && obj.warehousing_quantity.length <= 0 ? 0 : Number(obj.warehousing_quantity);
    obj.remaining_quantity = obj.remaining_quantity && obj.remaining_quantity.length <= 0 ? 0 : Number(obj.remaining_quantity);
    obj.shipment_quantity = obj.shipment_quantity && obj.shipment_quantity.length <= 0 ? 0 : Number(obj.shipment_quantity);
    obj.quantity = obj.quantity && obj.quantity.length <= 0 ? 0 : Number(obj.quantity);
    obj.digital_gifts = {};
    obj.campaign_event_prize = {
        id: form.event_prizes.id ?? null
    };
    if (digitalObject.id) {
        obj.digital_gifts[digitalObject.id] = {
            extension: {
                value_note: noteGift
            }
        };
    }

    obj.detail_information = {
        delivery_company: obj.detail_information.delivery_company,
        tracking_number: obj.detail_information.tracking_number,
        number_of_boxes_to_be_stocked: obj.detail_information.number_of_boxes_to_be_stocked,
        expiration_date: obj.detail_information.expiration_date,
        storage_warehouse: obj.detail_information.storage_warehouse,
        locator_number: obj.detail_information.locator_number
    };

    obj.remaining_response = {
        prize_less_status: {
            id: obj.remaining_response.prize_less_status?.id ?? null
        },
        reconfirmation_date: obj.remaining_response.reconfirmation_date,
        prize_refund_instructions: obj.remaining_response.prize_refund_instructions,
        preserve_fee: obj.remaining_response.preserve_fee,
        date_of_preserve: obj.remaining_response.date_of_preserve,
        reference: obj.remaining_response.reference
    };

    obj.number_of_applications = {
        total: Number(obj.number_of_applications.total) ?? 0,
        postcard_mail: Number(obj.number_of_applications.postcard_mail) ?? 0,
        web: Number(obj.number_of_applications.web) ?? 0,
        palnavi: Number(obj.number_of_applications.palnavi) ?? 0,
        sns: Number(obj.number_of_applications.sns) ?? 0,
        twitter: Number(obj.number_of_applications.twitter) ?? 0,
        instagram: Number(obj.number_of_applications.instagram) ?? 0,
        line: Number(obj.number_of_applications.line) ?? 0,
        others: Number(obj.number_of_applications.others) ?? 0,
        invalid: Number(obj.number_of_applications.invalid) ?? 0
    };

    obj.number_of_inquiries = {
        total: Number(obj.number_of_inquiries.total) ?? 0,
        tel: Number(obj.number_of_inquiries.tel) ?? 0,
        email: Number(obj.number_of_inquiries.email) ?? 0,
        number_of_cases: Number(obj.number_of_inquiries.number_of_cases) ?? 0,
        irregular: Number(obj.number_of_inquiries.irregular) ?? 0,
        irregular_content: obj.number_of_inquiries.irregular_content ?? ''
    };

    obj.is_packaging_photo_required = obj.is_packaging_photo_required !== 0 ? true : false;

    return cleanConfigFormData(obj);
}

export { handleDataDetail, initFormPrize, handleMapDataForm, PACKAGING_PHOTO };
